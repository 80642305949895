<template>
  <v-card class="my-4" elevation="4">
    <v-card-title><v-icon class="mr-2">mdi-store-cog</v-icon> Lojas</v-card-title>
    <v-card-text class="ma-2">
      <v-row no-gutters>
        <v-col class="pl-0 pr-4" cols="6">
          <div>
            <span class="pb-2">App Store</span>
            <v-text-field v-model.lazy="appStore" full-width outlined />
          </div>
        </v-col>
        <v-col class="px-0" cols="6">
          <div>
            <span class="pb-2">Play Store</span>
            <v-text-field v-model.lazy="playStore" full-width outlined />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="dialog = true"><v-icon class="mr-1">sync</v-icon> Atualizar Lojas</v-btn>
    </v-card-actions>

    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @cancel="closeDialog" @proceed="updateStoreLinks" />
  </v-card>
</template>

<script>
import { QUERY_CONFIGURATION, MUTATION_UPDATE_STORE_LINKS } from '@/modules/accounts/graphql'

export default {
  components: {
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialog: false,
    textDialog: 'Tem certeza que deseja atualizar as configurações de lojas mobile?',
    appStore: '',
    playStore: ''
  }),
  apollo: {
    configuration: {
      query: QUERY_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      update({ configuration }) {
        this.appStore = configuration?.store_links?.app_store
        this.playStore = configuration?.store_links?.play_store
      }
    }
  },
  methods: {
    setExternalLink(value, provider) {
      const PROVIDER_BASE_URL = {
        linkedin: 'linkedin.com/company/',
        app_store: 'apps.apple.com/br/app/',
        play_store: 'play.google.com/store/apps/details?id='
      }

      let baseUrl = PROVIDER_BASE_URL[provider] || ''
      if (!value || value === `https://${baseUrl}` || value === `https://www.${baseUrl}`) {
        return ''
      }
      if (value.includes(`https://${baseUrl}`) || value.includes(`https://www.${baseUrl}`)) {
        return value
      } else {
        return `https://${baseUrl}${value}`
      }
    },
    closeDialog() {
      this.dialog = false
      this.$apollo.queries.configuration.refetch()
    },
    async updateStoreLinks() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_STORE_LINKS,
          variables: {
            account_id: this.accountId,
            app_store: this.appStore,
            play_store: this.playStore
          }
        })
        this.$snackbar({ message: 'Lojas atualizadas com sucesso', snackbarColor: '#2E7D32' })
        this.closeDialog()
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao atualizar lojas', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
